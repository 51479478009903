.food {
  padding: rem(80px 0 100px);
  position: relative;
  &-decor {
    position: absolute;
    width:rem(194px);
    height: auto;
    left:rem(22px);
    top:0;
    @include media-breakpoint-down(xs) {
      width:6rem;
      left:1rem;
      top:0.5rem;
    }
  }
  &-holder {
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(md) {
      margin: 0 1.5rem;
    }
    &:before {
      content: "";
      position: absolute;
      top:rem(15px);
      left:rem(-22px);
      border:rem(4px solid cl(primary));
      border-radius: rem(40px);
      width:calc(100% + 2.75rem);
      height: calc(100% + 0.438rem);
    }
  }
  &-info {
    position: relative;
    z-index: 2;
    font-size: rem(20px);
    line-height:rem(22px);
    color:cl(black);
    background: cl(white);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .view-all {
      font-size: rem(15px);
      text-decoration: underline;
      color:cl(primary);
      &:hover {
        text-decoration: none;
      }
    }
  }
  h2 {
    position: relative;
    text-align: center;
    font-size: rem(30px);
    font-weight: 700;
    color:cl(black);
    margin: rem(0 0 8px);
    text-transform: uppercase;
    span {
      background: cl(white);
      padding: rem(0 15px);
    }
  }
  &-slider {
    background: #f1b252 url(../img/rays.png) no-repeat 50% 30%;
    background-size: 90% auto;
    border-radius: rem(30px);
    @include media-breakpoint-down(xs) {
      background-size: 100% auto;
      background-position: 50% 0;
    }
    .item {
      &:nth-child(even) {
        .food-slider-item {
          background: cl(white, 0.5);
        }
      }
      &:focus {
        outline: none;
      }
    }
    .slick-prev,
    .slick-next {
      position: absolute;
      left:rem(-37px);
      top:rem(110px);
      height: rem(70px);
      width:rem(32px);
      background: cl(white);
      border:0;
      &:focus {
        outline: none;
      }
      &:hover {
        span {
          background: cl(primary);
          color:cl(white);
        }
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width:rem(32px);
        height: rem(32px);
        border-radius: 50%;
        border:2px solid cl(primary);
        color:cl(primary);
        font-size: rem(15px);
      }
    }
    .slick-next {
      left:auto;
      right:rem(-37px);
    }
    &-item {
      padding: rem(25px);
    }
    &-image {
      height: rem(130px);
      width:rem(250px);
      margin: rem(0 auto 15px);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &-text {
      text-align: center;
      font-size: rem(15px);
      line-height: rem(25px);
      margin: rem(0 0 15px);
      &.text-scroll {
        height: rem(100px);
      }
      h3 {
        font-size: rem(18px);
        line-height: rem(25px);
        font-weight: 700;
        margin: rem(0 0 5px);
      }
      p {
        margin: 0;
      }
    }
    &-price {
      font-size: rem(30px);
      text-align: center;
    }
  }
}
.story {
  height: rem(455px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: rem(0 270px);
  margin: rem(98px 0);
  background: #f8d8a8;
  position: relative;
  @include media-breakpoint-down(lg) {
    height: auto;
    flex-direction: column;
    padding: rem(40px 0);
    margin-bottom: 2rem;
  }
  &-image {
    position: absolute;
    top:rem(-98px);
    left:rem(90px);
    width:rem(700px);
    height: rem(650px);
    @include media-breakpoint-down(lg) {
      position: static;
      margin: rem(-120px 0 40px);
    }
    @include media-breakpoint-down(xs) {
      width: 25.75rem;
      height: 24.1rem;
    }
  }
  &-logo {
    position: absolute;
    width:rem(319px);
    height: auto;
    top:rem(123px);
    left:90%;
    @include media-breakpoint-down(lg) {
      left:68%;
      width:16rem;
      top:11rem;
    }
    @include media-breakpoint-down(xs) {
      width:9rem;
    }
  }
  &-video {
    width:100%;
    height: 100%;
    overflow: hidden;
    border-radius: rem(30px);
    box-shadow:rem(0 32px 40px cl(black, .13));
    position: relative;
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 102%;
      height: 102%;
    }
  }
  &-text {
    width:rem(490px);
    color:cl(black);
    font-size: rem(18px);
    line-height: rem(30px);
    @include media-breakpoint-down(xs) {
      width:100%;
      padding: 0 1rem;
    }
    .text-scroll {
      height: rem(200px);
      margin: rem(0 0 20px);
      @include media-breakpoint-down(lg) {
        height: auto;
      }
    }
    h2 {
      font-size: rem(30px);
      font-weight: 700;
      margin: rem(0 0 20px);
      text-transform: uppercase;
    }
  }
  &-decor {
    position: absolute;
    &--1 {
      width:rem(228px);
      height: auto;
      top:rem(-140px);
      right: 0;
      @include media-breakpoint-down(lg) {
        width:rem(190px);
      }
      @include media-breakpoint-down(xs) {
        width: 6.875rem;
      }
    }
    &--2 {
      width:rem(231px);
      height: auto;
      top:76%;
      right: rem(25px);
      @include media-breakpoint-down(lg) {
        width:rem(150px);
      }
      @include media-breakpoint-down(xs) {
        top: auto;
        bottom:-3rem;
        right: 0.5rem;
      }
    }
    &--3 {
      width:rem(198px);
      height: auto;
      top:102%;
      right: rem(115px);
      @include media-breakpoint-down(lg) {
        width:rem(140px);
        top:90%;
      }
      @include media-breakpoint-down(xs) {
        right:7rem;
        width: 6rem;
        top: auto;
        bottom:-3rem;
      }
    }
  }
}
.discover {
  padding: rem(0 0 110px);
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    padding: rem(20px 0 50px);
  }
  &-grid {
    display: grid;
    grid-gap: rem(25px 16px);
    grid-template-columns: repeat(2, 1fr);
    @include media-breakpoint-down(xs) {
      grid-template-columns: 1fr;
    }
  }
  h2 {
    font-size: rem(30px);
    font-weight: 700;
    margin: rem(0 0 40px);
    text-transform: uppercase;
  }
  &-map {
    overflow: hidden;
    border-radius: rem(30px);
    height: rem(355px);
  }
  &-photo {
    display: grid;
    grid-gap: rem(16px);
    grid-template-columns: repeat(4, 1fr);
    &-item {
      padding-top: 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 0;
      border-radius: rem(20px);
    }
  }
}
.best {
  border-top:rem(6px solid cl(black));
  background: #f2b65b;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  &-back {
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    background: url(../img/best-bg.jpg) repeat-x 0 0;
    background-size: auto 100%;
    animation: animation-street 20s infinite linear;
    //animation-fill-mode: backwards;
    @include media-breakpoint-down(xs) {
      height: 20rem;
    }
  }
  &-inner {
    display: flex;
    justify-content: space-between;
    height: rem(400px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  }
  &-image {
    width:rem(673px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 4;
    @include media-breakpoint-down(md) {
      width:27rem;
    }
    @include media-breakpoint-down(xs) {
      height: 20rem;
      width:90%;
    }
    img {
      width:100%;
      height: auto;
      position: relative;
      z-index: 3;
      animation: animation-car 2s infinite linear;
    }
    &-shadow {
      margin-top: rem(-4px);
      width:100%;
      background: cl(black);
      height: rem(7px);
      border-radius: rem(3px);
      position: relative;
      z-index: 2;
      animation: animation-car-shadow 5s infinite linear;
    }
  }
  &-car {
    width:100%;
    height: auto;
  }
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: rem(60px);
    line-height: rem(60px);
    font-weight: 900;
    letter-spacing: rem(.2px);
    color: cl(white);
    text-shadow:rem(0 2px 0 cl(black),2px 0 0 cl(black),-2px 0 0 cl(black),0 -2px 0 cl(black),2px 2px 0 cl(black),2px -2px 0 cl(black),-2px 2px 0 cl(black),-2px -2px 0 cl(black));
    margin: rem(0 0 35px);
    @include media-breakpoint-down(lg) {
      font-size: rem(50px);
      line-height: rem(50px);
    }
    @include media-breakpoint-down(md) {
      font-size: rem(40px);
      line-height: rem(40px);
    }
  }
  .btn {
    color: cl(white);
    border-color:cl(black);
    width:rem(140px);
    margin: rem(0 11px);
    padding: 0;
  }
  &-text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: "";
      @extend .pos-center;
      //left:-10%;
      //top:-20%;
      //width:120%;
      //height: 140%;
      width:rem(900px);
      height: rem(900px);
      background: radial-gradient(ellipse at center,  rgba(242,182,91,1) 50%, rgba(242,182,91,0) 70%);
      @include media-breakpoint-down(xs) {
        width:30rem;
        height: 30rem;
      }

    }
    &:after {
      content: "";
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: 100%;
      background: url(../img/rays.png) no-repeat 50% 30%;
      background-size: auto 120%;
    }
    &-inner {
      position: relative;
      z-index: 3;
      padding: rem(0 0 60px);
      @include media-breakpoint-down(xs) {
        padding: 2rem 0 3.75rem;
      }
    }
  }
  .buttons {
    position: relative;
    z-index: 3;
  }
  &-decor {
    position: absolute;
    height: auto;
    &--1 {
      width:rem(27px);
      bottom: rem(120px);
      left:rem(-30px);
      animation: animation-move-horizontal 8s infinite linear;
      @include media-breakpoint-down(xs) {
        left:-1rem;
      }
    }
    &--2 {
      width:rem(63px);
      bottom: rem(70px);
      left:rem(0);
      animation: animation-move-vertical 8s infinite linear;
    }
    &--3 {
      width:rem(147px);
      bottom: 0;
      left:rem(32px);
      animation: animation-rotate-reverse 8s infinite linear;
      @include media-breakpoint-down(xs) {
        width:6rem;
      }
    }
    &--4 {
      width:rem(123px);
      bottom: 0;
      right:rem(55px);
      animation: animation-rotate 8s infinite linear;
      @include media-breakpoint-down(xs) {
        width:4rem;
      }
    }
    &--5 {
      width:rem(164px);
      bottom: 0;
      right:rem(-155px);
      animation: animation-move-triangle 8s infinite linear;
      @include media-breakpoint-down(xs) {
        width: 6.25rem;
        bottom: 4rem;
        right: -2.6875rem;
      }
    }
  }
}
@-webkit-keyframes animation-car {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes animation-car {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes animation-car-shadow {
  0% {
    transform: scale(1.0);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.0);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.0);
  }
}
@keyframes animation-car-shadow {
  0% {
    transform: scale(1.0);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.0);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.0);
  }
}
@-webkit-keyframes animation-street {
  from {
  }
  to {
    background-position: -100vw 0;
  }
}
@keyframes animation-street {
  from {
  }
  to {
    background-position: -100vw 0;
  }
}
@-webkit-keyframes animation-move-vertical {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -25%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes animation-move-vertical {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -25%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes animation-move-horizontal {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(25%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@-webkit-keyframes animation-move-horizontal {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(25%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes animation-rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0);
  }
}
@-webkit-keyframes animation-rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes animation-rotate-reverse {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0);
  }
}
@-webkit-keyframes animation-rotate-reverse {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes animation-move-triangle {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(15%, -5%);
  }
  66% {
    transform: translate(15%, 5%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@-webkit-keyframes animation-move-triangle {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(15%, -5%);
  }
  66% {
    transform: translate(15%, 5%);
  }
  100% {
    transform: translate(0, 0);
  }
}