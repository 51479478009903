.footer {
  background: cl(dark);
  padding: rem(55px 40px 30px);
  color:cl(white);
  display: grid;
  grid-gap: rem(16px);
  grid-template-columns: repeat(3, 1fr);
  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;
    padding: rem(30px 20px 30px);
  }
  &-logo {
    display: block;
    margin: rem(0 auto 20px);
    width:rem(200px);
    img {
      width:100%;
      height: auto;
    }
  }
  &-center {
    text-align: center;
    font-size: rem(15px);
    margin: rem(0 0 0 10px);
  }
  &-logos {
    padding: rem(30px 0 0);
    &-items {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      @include media-breakpoint-down(xs) {
        justify-content: center;
      }
    }
    img {
      max-width:rem(130px);
      max-height: rem(70px);
      margin: rem(0 30px 30px 0);
      @include media-breakpoint-down(lg) {
        max-width:rem(100px);
        max-height: rem(50px);
      }
      @include media-breakpoint-down(xs) {
        max-width:rem(60px);
        max-height: rem(40px);
        margin: rem(0 10px 10px);
      }
    }
  }
  &-right {
    padding: rem(30px 0 0);
  }
  &-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: rem(0 0 20px);
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(38px);
      color:cl(white);
      margin-left: rem(16px);
      border:rem(1px solid cl(white));
      @include media-breakpoint-down(xs) {
        margin: rem(0 10px);
      }
      &:hover {
        text-decoration: none;
        color:cl(secondary);
        border-color:cl(secondary);
      }
      i {
        font-size: rem(16px);
      }
    }
  }
  &-copy {
    display: block;
    text-align: right;
    color:#646363;
    font-size: rem(13px);
    line-height: rem(23px);
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
}

