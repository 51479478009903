.static {
  font-size: rem(16px);
  line-height: rem(30px);
  color:cl(text);
  p {
    margin: rem(0 0 20px);
  }
  h2 {
    font-size: rem(24px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  h3 {
    font-size: rem(22px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  h4 {
    font-size: rem(20px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  h5 {
    font-size: rem(18px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  h6 {
    font-size: rem(16px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  ol, ul {
    margin: rem(0 0 20px);
    padding: rem(0 0 0 20px);
  }
  img {
    display: block;
    margin: 0 auto 2rem;
    max-width:90%;
    height: auto;
  }
}