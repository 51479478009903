.menu {
  &--section {
    padding: rem(145px 0 80px);
    position: relative;
    overflow: hidden;
    h2 {
      font-size: rem(30px);
      font-weight: 700;
      margin: rem(0 0 30px);
      text-transform: uppercase;
      position: relative;
      img {
        position: absolute;
        left:50%;
        width:rem(8px);
        margin-left: rem(-4px);
        height: auto;
        bottom: calc(100% + 1rem);
        animation: animation-wave 2.5s infinite linear;
      }
    }
  }
  &--page {
    padding: rem(55px 0 80px);
    position: relative;
    font-size: rem(18px);
    line-height: rem(28px);
    color:black;
    h2 {
      font-size: rem(30px);
      font-weight: 700;
      margin: rem(0 0 15px);
      text-transform: uppercase;
    }
  }
  &-content {
    position: relative;
  }
  &-filter {
    padding: rem(45px 0 0);
    position: absolute;
    top:0;
    left:rem(-260px);
    width:rem(220px);
    @include media-breakpoint-down(lg) {
      position: static;
      padding: rem(0 0 45px);
      margin: 0 auto;
    }
    h4 {
      border-bottom: 2px solid cl(dark);
      text-transform: uppercase;
      font-size: rem(18px);
      font-weight: 700;
      padding: rem(0 0 5px);
      margin: rem(0 0 5px);
    }
    &-item {
      display: block;
      border-bottom: 1px solid #dedede;
      padding: rem(15px 30px 15px 0);
      position: relative;
      color:black;
      font-size: rem(18px);
      font-weight: 700;
      &:before {
        content: '\f105';
        position: absolute;
        top:rem(16px);
        right: rem(15px);
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: rem(16px);
      }
      &:hover,
      &.current {
        text-decoration: none;
        color:cl(secondary);
      }
    }
  }
  &-grid {
    display: grid;
    grid-gap: rem(25px 16px);
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      grid-template-columns: 1fr;
    }
  }
  &-item {
    position: relative;
    &:hover {
      .menu-item-img {
        transform: scale(1.1);
      }
      .menu-item-mask {
        transform: scale(1.0);
        opacity: 1;
      }
    }
    h3 {
      position: absolute;
      @extend .pos-centerX;
      top:rem(-12px);
      width:rem(215px);
      height: rem(46px);
      display: flex;
      justify-content: center;
      align-items: center;
      color:cl(white);
      background: cl(primary);
      border-radius: rem(0 0 25px 25px);
      text-transform: uppercase;
      font-family: $font;
      font-size: rem(16px);
      letter-spacing: rem(1px);
      box-shadow:rem(0 4px 8px cl(black, 0.27));
      z-index: 3;
    }
    &-image {
      overflow: hidden;
      position: relative;
      border-radius: rem(30px);
      padding-top: 50%;
    }
    &-img {
      @extend .full;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      transform: scale(1.0);
      transition: all .3s ease;
    }
    &-mask {
      position: absolute;
      left:0;
      top:0;
      right:0;
      bottom: 0;
      background: cl(black, .6);
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(1.1);
      opacity: 0;
      transition: all .3s ease;
    }
  }
  &-list {
    display: grid;
    grid-gap: rem(35px);
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(2, 1fr);
    }
    &-item {
      text-align: center;
      font-size: rem(15px);
      line-height: rem(25px);
    }
    &-image {
      height: 0;
      padding-top: 100%;
      border-radius: rem(20px);
      margin: rem(0 0 20px);
      overflow: hidden;
      position: relative;
      cursor: pointer;
      &:hover {
        .menu-list-image-inner {
          transform: scale(1.1);
          &:before {
            opacity: .2;
          }
        }
        i {
          transform: scale(1.0) translate(-50%,-50%);
          opacity: 1;
        }
      }
      &-inner {
        @extend .full;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        transition: all .3s ease;
        &:before {
          content: "";
          @extend .full;
          background: black;
          opacity:0;
        }
      }
      i {
        @extend .pos-center;
        color:cl(white);
        font-size: rem(28px);
        transition: all .3s ease;
        transform: scale(1.5) translate(-50%,-50%);
        opacity: 0;
      }
    }
    h3 {
      font-size: rem(18px);
      font-weight: 600;
      margin: rem(0 0 5px);
    }
    p {
      margin: 0;
    }
    &-price {
      display: block;
      padding: rem(5px 0 0);
      font-size: rem(20px);
    }
  }
  &-decor {
    position: absolute;
    height: auto;
    &--1 {
      width:rem(408px);
      left:rem(40px);
      bottom:rem(100px);
      @include media-breakpoint-down(lg) {
        width: 14.5rem;
        left: 1.5rem;
        bottom: 1.25rem;
      }
    }
    &--2 {
      width:rem(202px);
      left:rem(240px);
      bottom:rem(0px);
      @include media-breakpoint-down(lg) {
        width: 8rem;
        left: 16rem;
      }
    }
    &--3 {
      width:rem(194px);
      height: auto;
      left:rem(22px);
      top:rem(40px);
      @include media-breakpoint-down(xs) {
        width:6rem;
        left:1rem;
        top:0.5rem;
      }
    }
    &--4 {
      width:rem(194px);
      height: auto;
      right:rem(22px);
      top:rem(40px);
      @include media-breakpoint-down(xs) {
        width:6rem;
        right:1rem;
        top:0.5rem;
      }
    }
  }
}