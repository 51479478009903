.about {
  padding: rem(80px 0);
  text-align: center;
  font-size: rem(18px);
  line-height: rem(30px);
  @include media-breakpoint-down(xs) {
    padding: 3rem 0;
  }
  h2 {
    font-size: rem(30px);
    font-weight: 700;
    color:cl(black);
    margin: rem(0 0 35px);
    text-transform: uppercase;
    position: relative;
    img {
      position: absolute;
      left:50%;
      width:rem(8px);
      margin-left: rem(-4px);
      height: auto;
      bottom: calc(100% + 1rem);
      animation: animation-wave 2.5s infinite linear;
    }
  }
  p {
    margin: rem(0 0 25px);
  }
}