/* banner start */
.banner {
  position: relative;
  &--home {
    height: 100vh;
    @include media-breakpoint-down(xs) {
      height: 36rem;
    }
  }
  &--page {
    height: rem(500px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include media-breakpoint-down(md) {
      height: 22rem;
      background-attachment: scroll;
    }
    &:before {
      content: '';
      position: absolute;
      @extend .full;
      background: cl(black, 0.45);
      z-index: 2;
    }
    .container {
      position: relative;
      z-index: 3;
    }
    .banner-order-wave {
      bottom: rem(10px);
      z-index: 3;
      display: block!important;
    }
    .banner-stamp {
      bottom: rem(25px);
      right:rem(100px);
      @include media-breakpoint-down(md) {
        right: 2rem;
      }
      @include media-breakpoint-down(md) {
        width:10rem;
      }
      @include media-breakpoint-down(md) {
        width:7rem;
        right: 2rem;
        left:auto;
      }
    }
    h1 {
      display: block;
      font-size: rem(60px);
      line-height: rem(80px);
      margin: 0;
      font-weight: 700;
      letter-spacing: rem(0.2px);
      text-transform: uppercase;
      color:cl(white);
      @include media-breakpoint-down(sm) {
        font-size: rem(40px);
        line-height: rem(60px);
      }
      @include media-breakpoint-down(xs) {
        //font-size: rem(30px);
        //line-height: rem(40px);
      }
    }
  }
  &-slider {
    overflow: hidden;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      @extend .full;
      background: cl(black, 0.45);
      z-index: 2;
    }
    .item {
      &:focus {
        outline: none;
      }
    }
    .slick-prev,
    .slick-next {
      position: absolute;
      left:rem(40px);
      top:50%;
      color:cl(white);
      font-size: rem(30px);
      background: none;
      border:0;
      z-index: 201;
      @include media-breakpoint-down(xs) {
        left:1rem;
      }
    }
    .slick-next {
      left:auto;
      right:rem(40px);
      @include media-breakpoint-down(xs) {
        right:1rem;
      }
    }
    &-item {
      height: calc(100vh - 2.5rem);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      @include media-breakpoint-down(xs) {
        height: 33.5rem;
      }
    }
  }
  &-text {
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    color:cl(white);
    text-transform: uppercase;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .ttl {
      display: block;
      font-size: rem(60px);
      line-height: rem(80px);
      margin: rem(0 0 5px);
      font-weight: 700;
      letter-spacing: rem(0.2px);
      @include media-breakpoint-down(sm) {
        font-size: rem(40px);
        line-height: rem(60px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(30px);
        line-height: rem(40px);
      }
    }
    .txt {
      display: block;
      font-size: rem(20px);
      line-height: rem(30px);
      margin: rem(0 0 30px);
      letter-spacing: rem(3px);
      @include media-breakpoint-down(xs) {
        font-size: rem(16px);
        line-height: rem(20px);
      }
    }
    p {
      margin: 0;
    }
  }
  &-stamp {
    position: absolute;
    right:rem(105px);
    bottom: rem(115px);
    width:rem(224px);
    height: auto;
    z-index: 202;
    @include media-breakpoint-down(lg) {
      right: 1rem;
      bottom: 16rem;
    }
    @include media-breakpoint-down(xs) {
      right:auto;
      left:1rem;
      bottom: 8rem;
      width: 5rem;
    }
  }
  &-bottom {
    position: absolute;
    bottom: rem(-50px);
    left:0;
    width:100%;
    z-index: 202;
  }
  &-order {
    position: relative;
    .item {
      &:focus {
        outline: none;
      }
    }
    &-wave {
      position: absolute;
      width:rem(8px);
      height: auto;
      bottom: rem(170px);
      left:50%;
      margin-left: rem(-4px);
      animation: animation-wave 2.5s infinite linear;
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
    &-body {
      position: relative;
      width:rem(764px);
      margin: rem(151px auto 50px);
      border:rem(6px solid #ffbe2e);
      height: rem(108px);
      border-radius: rem(54px);
      box-shadow:rem(0 10px 30px cl(black, .4));
      background: cl(primary);
      padding: rem(0 150px 0 65px);
      &--dark {
        background: cl(dark);
      }
      @include media-breakpoint-down(xs) {
        width: 25rem;
      }
    }
    .btn {
      @include media-breakpoint-down(xs) {
        font-size: rem(13px);
        line-height: rem(36px);
      }
    }
    &-ubereat {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      @include media-breakpoint-down(xs) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      &-txt {
        color:cl(white);
        font-size: rem(18px);
        @include media-breakpoint-down(xs) {
          font-size: rem(16px);
          margin: rem(0 0 5px);
        }
        img {
          width:rem(315px);
          height: auto;
          margin: rem(8px 0 0);
          @include media-breakpoint-down(xs) {
            width:10rem;
            margin: rem(3px 0 0);
          }
        }
      }
    }
    &-inner {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      text-align: center;
      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }
      &-txt {
        color:cl(white);
        font-size: rem(24px);
        text-transform: uppercase;
        @include media-breakpoint-down(xs) {
          font-size: rem(14px);
        }
      }
      &-icon {
        display: block;
        fill: cl(white);
        margin: rem(0 auto);
        &--1 {
          width:rem(54px);
          height: rem(54px);
          @include media-breakpoint-down(xs) {
            width:1.5rem;
            height: 1.5rem;
          }
        }
        &--2 {
          width:rem(40px);
          height: rem(40px);
          margin-bottom: rem(14px);
          @include media-breakpoint-down(xs) {
            width:1.2rem;
            height: 1.2rem;
            margin-bottom: rem(5px);
          }
        }
      }
      &-img {
        svg {
          width:rem(41px);
          height: rem(41px);
          fill: #ffbe2e;
          @include media-breakpoint-down(xs) {
            width:1rem;
            height: 1rem;
          }
        }
      }
    }
    &-phone {
      position: absolute;
      bottom: 0;
      right:rem(17px);
      width:rem(165px);
      height: auto;
      @include media-breakpoint-down(xs) {
        width:6rem;
      }
    }
  }
}
/* banner end */


@keyframes animation-wave {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -1rem);
  }
  100% {
    transform: translate(0, 0);
  }
}