.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	&:before {
		content: "";
		position: absolute;
		left:0;
		top:0;
		width:100%;
		height: rem(142px);
		background: linear-gradient(180deg, rgba(0,0,0,.9) 0%, rgba(0,0,0,.8) 20%, rgba(0,0,0,0) 100%);
	}
	&-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: rem(0 40px);
		height: rem(123px);
		position: relative;
		z-index: 2;
		@include media-breakpoint-down(xs) {
			padding: rem(0 20px);
		}
	}
	&-logo {
		width:rem(264px);
		flex-shrink: 0;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	&-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width:100%;
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
		}
	}
	&-nav {
		width:100%;
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			position: fixed;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: 8rem 0 0;
			background: cl(black);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		&-logo {
			display: none;
			position: absolute;
			top:1.5rem;
			width:18rem;
			@extend .pos-centerX;
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}
		& > ul {
			display: flex;
			align-items: center;
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				height: 100%;
				width:100%;
				overflow: auto;
				display: block;
			}
			& > li {
				position: relative;
				margin-right: rem(35px);
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;
					border-top:rem(1px solid cl(white, 0.1));
					&:last-child {
						border-bottom:rem(1px solid cl(white, 0.1));
					}
				}
				& > a {
					display: block;
					font-size: rem(16px);
					line-height: rem(18px);
					text-transform: uppercase;
					color:cl(secondary);
					letter-spacing: rem(0.2px);
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1.5rem 2rem;
						text-align: center;
						color:cl(white);
					}
					&:hover {
						text-decoration: none;
						color:cl(white);
					}
				}
				&.active {
					& > a {
						text-decoration: none;
						color:cl(white);
						@include media-breakpoint-down(lg) {
							color:cl(white);
							background:cl(secondary);
						}
					}
				}
				&:hover {
					.drop {
						display: block;
					}
				}
				.drop {
					position: absolute;
					top:rem(18px);
					padding-top: rem(21px);
					left:0;
					width:rem(170px);
					display: none;
					@include media-breakpoint-down(lg) {
						display: block;
						position: static;
						width:100%;
						padding: 0;
						margin: 0;
					}
					ul {
						margin: 0;
						background: cl(dark);
						list-style: none;
						box-shadow:rem(1px 6px 8px cl(black, .36));
						border-radius: rem(0 0 8px 8px);
						padding: rem(8px 0);
						overflow: hidden;
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: none;
						}
						li {
							a {
								display: block;
								font-size: rem(16px);
								line-height: rem(18px);
								color:cl(white);
								padding: rem(12px 10px 12px 20px);
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1.5rem 2rem;
									text-align: center;
									border-top:rem(1px solid cl(white, .1));
								}
								&:hover {
									text-decoration: none;
									background: cl(secondary);
									color:cl(dark);
								}
							}
							&.active {
								a {
									text-decoration: none;
									background: cl(secondary);
									color:cl(dark);
								}
							}
						}
					}
				}
			}
		}
	}
	&-buttons {
		position: relative;
		display: flex;
		align-items: center;
		margin: rem(0 0 0 35px);
		@include media-breakpoint-down(sm) {
			margin: 0;
			padding: 1rem;
			justify-content: flex-end;
			width:100%;
		}
		.btn {
			margin: rem(0 0 0 23px);
			line-height: rem(46px);
			border-width:rem(2px);
			@include media-breakpoint-down(xs) {
				width:48%;
				margin: 0;
			}
		}
	}
}

/*hamburger btn*/
.btn-lines {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height:rem(2px);
	background-color: cl(primary);
	content: "";
	transition: all 0.3s ease;
}
.c-hamburger {
	display: none;
	position: relative;
	z-index: 10;
	margin: rem(0 0 0 40px);
	padding: 0;
	height:rem(20px);
	width:rem(26px);
	flex-shrink: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	border: none;
	cursor: pointer;
	transition: all 0.3s ease  0.3s;
	&:focus {
		outline: none;
	}
	@include media-breakpoint-down(lg){
		display: block;
	}
	span {
		display: block;
		position: absolute;
		top:rem(9px);
		left: 0;
		right: 0;
		height:rem(2px);
		background: cl(primary);
		transition: all 0.3s ease;
	}
	span::before {
		@extend .btn-lines;
		top:rem(-7px);
	}
	span::after {
		@extend .btn-lines;
		bottom:rem(-7px);
	}
	&--htx {
		background-color: transparent;
		&.active {
			&:before {
				display: none;
			}
			span {
				background: none;
			}
			span::before {
				top: 0;
				transform:rotate(45deg);
				//background: cl(white);
			}
			span::after {
				bottom: 0;
				transform:rotate(-45deg);
				//background: cl(white);
			}
		}
	}
}
/*hamburger btn*/
