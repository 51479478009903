.btn {
  @extend .inline-b;
  @extend .ttu;
  background: cl(primary);
  color:cl(white);
  font-size: rem(15px);
  line-height: rem(50px);
  font-family: $font;
  font-weight: 700;
  border: rem(3px solid cl(primary));
  cursor: pointer;
  border-radius: rem(28px);
  padding: rem(0 30px);
  outline: 0;
  white-space: nowrap;
  letter-spacing: rem(1px);
  &:hover {
    border-color:cl(primary);
    background: cl(primary, 0.9);
    color:cl(white);
    text-decoration: none;
  }
  &:focus {
    box-shadow:none;
    color:cl(white);
  }
  &--primary-outline {
    background: none;
    color:cl(primary);
    border-color:cl(primary);
    &:hover {
      background: cl(primary, 0.1);
      color:cl(primary);
    }
  }
  &--border-secondary {
    border-color:cl(secondary);
    &:hover {
      border-color:cl(secondary);
    }
  }
  &--white-outline {
    background: none;
    color:cl(white);
    border-color:cl(white);
    &:hover {
      background: cl(white, 0.1);
      border-color:cl(white);
    }
  }
  &--black {
    background: cl(black);
    border-color:cl(secondary);
    color:cl(secondary);
    &:hover {
      background: cl(black, .8);
      border-color:cl(secondary);
      color:cl(secondary);
    }
  }
  &--ubereat {
    background: cl(ubereat);
    border: rem(2px solid cl(black));
    &:hover {
      background: cl(ubereat, .8);
      border-color:cl(black);
    }
  }
}